@import '../../styles/variables.scss';

.input {
  opacity: 0;
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.target {
  background-color: $white;
  border-radius: $roundness-m;
  border: 1px solid $n2;
  cursor: pointer;
  display: block;
  height: 18px;
  position: relative;
  width: 18px;

  &::after {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGQ9Ik04LDEzIEM3LjQ0NzcxNTI1LDEzIDcsMTIuNTUyMjg0NyA3LDEyIEM3LDExLjQ0NzcxNTMgNy40NDc3MTUyNSwxMSA4LDExIEwxMCwxMSBMMTAsNSBDMTAsNC40NDc3MTUyNSAxMC40NDc3MTUzLDQgMTEsNCBDMTEuNTUyMjg0Nyw0IDEyLDQuNDQ3NzE1MjUgMTIsNSBMMTIsMTIgTDEyLDEyIEMxMiwxMi41MTI4MzU4IDExLjYxMzk1OTgsMTIuOTM1NTA3MiAxMS4xMTY2MjExLDEyLjk5MzI3MjMgTDExLDEzIEwxMSwxMyBMOCwxMyBaIiB0cmFuc2Zvcm09InJvdGF0ZSg0NSAxNC4yNDI2IC0uNTM1NSkiIGZpbGw9IiMzMjc5RDYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4=');
    background-position: center center;
    background-repeat: no-repeat;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.15s ease;
    width: 100%;
  }

  &:active {
    background-color: $n4;
  }
}

.target--checked::after {
  opacity: 1;
}
