:global {
  html,
  body {
    font-size: 14px;
  }

  body,
  input,
  textarea {
    font-family: $font-stack;
    color: $n0;
  }

  h1 {
    color: $n0;
    font-size: 2.285714286rem;
    font-weight: 700;
  }

  h2 {
    font-size: 1.285714286rem;
    font-weight: 700;
  }

  h3 {
    font-size: 1.285714286rem;
    line-height: 1.65;
    font-weight: 500;
  }

  p {
    font-size: 1rem;
    line-height: 1.571428571;
  }

  a {
    color: $brand;
    outline: none;
    text-decoration: none;
  }

  b {
    font-weight: 500;
  }

  small {
    color: $n1;
    font-size: 1rem;
  }
}
