@import '../../styles/variables.scss';

.wrap {
  position: relative;
}

.input {
  appearance: none;
  background-color: $white;
  border-radius: $roundness-m;
  border: 1px solid $n2;
  color: $n0;
  font-family: $font-stack;
  font-size: 1.142857143rem;
  height: 40px;
  outline: none;
  padding: 0 $space-xs;
  width: 100%;

  &:hover {
    border-color: $n3;
  }

  &:focus {
    border-color: $brand;
  }

  &::placeholder {
    color: $n3;
  }

  &[type='number'] {
    padding-right: $space-xxs;
  }
}
