@import '../../styles/variables.scss';

.fillLater {
  margin-bottom: $space-xs;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: $space-m $space-m 0 $space-m;
  width: 100%;
}
