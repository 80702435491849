@import '../../styles/variables.scss';

.wrap {
  background-color: $n4;
  height: 100vh;
  overflow: auto;
  width: 100vw;
}

.wordmark {
  margin-top: $space-xl;
  text-align: center;
}

.content {
  align-items: center;
  background-color: $white;
  border-radius: $roundness-m;
  display: flex;
  flex-direction: column;
  margin: $space-xl auto;
  max-width: 900px;
  padding: $space-l 0 $space-m;
  text-align: center;
  width: 100%;
}

.title {
  margin-bottom: $space-xxs;
}

.subtitle {
  color: $n1;
  margin-bottom: $space-l;
}

.footer {
  color: $n1;
  margin-bottom: $space-xl;
  text-align: center;
}
