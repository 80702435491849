@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  border-bottom: 1px solid $n3;
  display: flex;
  height: 90px;
  justify-content: space-between;
  padding: 0 $space-m;
  text-align: left;
  width: 100%;
}

.title {
  margin-bottom: $space-xxs;
}

.subtitle {
  color: $n1;
}

.content {
  align-items: center;
  display: flex;
  justify-content: right;
}
