@import '../../styles/variables.scss';

.wrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.nav a {
  color: $n0;
  font-weight: 600;
  margin-right: $space-l;
}
