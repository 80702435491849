@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
.PublicNavigation_wrap__Q48UZ {
  align-items: center;
  display: flex;
  justify-content: space-between; }

.PublicNavigation_nav__1St9o a {
  color: #101010;
  font-weight: 600;
  margin-right: 36px; }

.Loading_wrap__v-HDr {
  overflow: hidden; }

.Loading_circle__3zRhK {
  fill: #cccccc; }

.Loading_swig__3gfi5 {
  -webkit-animation: Loading_wiggle__20XHb 1.2s linear infinite;
          animation: Loading_wiggle__20XHb 1.2s linear infinite; }

.Loading_theme--default__2dCO5 .Loading_circle__3zRhK {
  fill: #cccccc; }

.Loading_theme--default__2dCO5 .Loading_swig__3gfi5 {
  fill: #ffffff; }

.Loading_theme--blue__2pRdv .Loading_circle__3zRhK {
  fill: rgba(255, 255, 255, 0.75); }

.Loading_theme--blue__2pRdv .Loading_swig__3gfi5 {
  fill: #3279d6; }

/* prettier-ignore */
@-webkit-keyframes Loading_wiggle__20XHb {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  25% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px); }
  50% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  75% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }
@keyframes Loading_wiggle__20XHb {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  25% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px); }
  50% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  75% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.Button_button__2Lf63 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #3279d6;
  border-radius: 6px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  font-family: "Open Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 700;
  height: 36px;
  outline: none;
  padding: 0 24px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .Button_button__2Lf63[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }

.Button_text__2RPhU {
  align-items: center;
  display: flex;
  justify-content: center; }

.Button_loading__2nORI {
  pointer-events: none; }
  .Button_loading__2nORI .Button_text__2RPhU {
    visibility: hidden; }

.Button_icon__36xd- {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 6px; }

.Button_loader__3tdiH {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .Button_loader__3tdiH svg {
    display: block; }

/** Themes */
.Button_default__165Bx {
  background-color: #3279d6; }
  .Button_default__165Bx:hover {
    background-color: #4384d9; }
  .Button_default__165Bx:active {
    background-color: #296fcb; }

.Button_secondary__1gv5E {
  background-color: transparent;
  border: 2px solid #101010;
  color: #101010; }
  .Button_secondary__1gv5E:hover {
    background-color: #f4f4f4; }
  .Button_secondary__1gv5E:active {
    background-color: #dadada; }

.Button_fill__3N0Y_ {
  width: 100%; }

.Home_header__1UPKb {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 92vh;
  justify-content: flex-start;
  max-height: 1000px;
  padding: 24px;
  position: relative; }

.Home_publicNavigation__2XCfC {
  width: 100%; }

.Home_welcome__1jNjT {
  margin: 76px 0;
  max-width: 900px;
  text-align: center; }

.Home_screenshot__2tP0a {
  height: calc(85vh - 112px);
  left: 50%;
  max-width: 900px;
  overflow: hidden;
  position: absolute;
  top: 112px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%; }
  .Home_screenshot__2tP0a img {
    width: 100%; }

.Home_text__2q6HY {
  background-color: #ffffff;
  bottom: 0;
  left: 50%;
  max-width: 900px;
  padding: 24px 0 76px 0;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%; }
  .Home_text__2q6HY::before {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(90%, white));
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 90%);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 90%);
    content: '';
    height: 150px;
    left: 0;
    position: absolute;
    top: -150px;
    width: 100%; }

.Home_title__3Z2X_ {
  margin-bottom: 24px; }

.Home_subtitle__3jEdG {
  max-width: 575px;
  margin: 0 auto 36px auto; }

.Home_signUp__iwNDF {
  margin-bottom: 18px; }

.Home_features__1emIG {
  background-color: #f4f4f4;
  height: 800px; }

.LoadingFrame_frame__2_YhC {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%; }

.LoadingFrame_fullPage__3ONFz {
  height: 100vh;
  width: 100vw; }

.AuthButton_wrap__2xF5W {
  margin-bottom: 18px;
  width: 230px; }

.AuthButton_icon__349wx {
  margin-right: 14px; }
  .AuthButton_icon__349wx svg {
    display: block; }

.AuthButton_provider--Google__m--bi button {
  background-color: #4285f4 !important; }
  .AuthButton_provider--Google__m--bi button:hover {
    background-color: #4c8bf5 !important; }
  .AuthButton_provider--Google__m--bi button:active {
    background-color: #2f79f3 !important; }

.AuthButton_provider--Twitter__20zje button {
  background-color: #1da1f2 !important; }
  .AuthButton_provider--Twitter__20zje button:hover {
    background-color: #27a5f3 !important; }
  .AuthButton_provider--Twitter__20zje button:active {
    background-color: #0e98ed !important; }

.AuthButton_provider--Github__22MC6 button {
  background-color: #333 !important; }
  .AuthButton_provider--Github__22MC6 button:hover {
    background-color: #383838 !important; }
  .AuthButton_provider--Github__22MC6 button:active {
    background-color: #292929 !important; }

.SignUpFrame_wrap__3gxAx {
  background-color: #f4f4f4;
  height: 100vh;
  overflow: auto;
  width: 100vw; }

.SignUpFrame_wordmark__2Ey8f {
  margin-top: 76px;
  text-align: center; }

.SignUpFrame_content__CBaGw {
  align-items: center;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 76px auto;
  max-width: 900px;
  padding: 36px 0 24px;
  text-align: center;
  width: 100%; }

.SignUpFrame_title__2ilsF {
  margin-bottom: 6px; }

.SignUpFrame_subtitle__3Z7_D {
  color: #6a6a6a;
  margin-bottom: 36px; }

.SignUpFrame_footer__1zD8w {
  color: #6a6a6a;
  margin-bottom: 76px;
  text-align: center; }

.SettingsRow_wrap__PooLU {
  align-items: center;
  border-bottom: 1px solid #dadada;
  display: flex;
  height: 90px;
  justify-content: space-between;
  padding: 0 24px;
  text-align: left;
  width: 100%; }

.SettingsRow_title__2FreA {
  margin-bottom: 6px; }

.SettingsRow_subtitle__3Lwi1 {
  color: #6a6a6a; }

.SettingsRow_content__34J5Z {
  align-items: center;
  display: flex;
  justify-content: right; }

.Checkbox_input__JC9f1 {
  opacity: 0;
  position: absolute;
  left: -9999px;
  top: -9999px; }

.Checkbox_target__17QsW {
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #cccccc;
  cursor: pointer;
  display: block;
  height: 18px;
  position: relative;
  width: 18px; }
  .Checkbox_target__17QsW::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGQ9Ik04LDEzIEM3LjQ0NzcxNTI1LDEzIDcsMTIuNTUyMjg0NyA3LDEyIEM3LDExLjQ0NzcxNTMgNy40NDc3MTUyNSwxMSA4LDExIEwxMCwxMSBMMTAsNSBDMTAsNC40NDc3MTUyNSAxMC40NDc3MTUzLDQgMTEsNCBDMTEuNTUyMjg0Nyw0IDEyLDQuNDQ3NzE1MjUgMTIsNSBMMTIsMTIgTDEyLDEyIEMxMiwxMi41MTI4MzU4IDExLjYxMzk1OTgsMTIuOTM1NTA3MiAxMS4xMTY2MjExLDEyLjk5MzI3MjMgTDExLDEzIEwxMSwxMyBMOCwxMyBaIiB0cmFuc2Zvcm09InJvdGF0ZSg0NSAxNC4yNDI2IC0uNTM1NSkiIGZpbGw9IiMzMjc5RDYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4=");
    background-position: center center;
    background-repeat: no-repeat;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
    width: 100%; }
  .Checkbox_target__17QsW:active {
    background-color: #f4f4f4; }

.Checkbox_target--checked__2OHfS::after {
  opacity: 1; }

.DayOfWeek_wrap__KXVPf {
  text-align: center;
  width: 18px;
  margin-left: 12px; }

.DayOfWeek_label__1XmBD {
  color: #6a6a6a;
  cursor: pointer;
  font-weight: 700;
  padding-top: 6px;
  text-transform: uppercase;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.Input_wrap__1N7eV {
  position: relative; }

.Input_input__3rPb3 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #cccccc;
  color: #101010;
  font-family: "Open Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.142857143rem;
  height: 40px;
  outline: none;
  padding: 0 14px;
  width: 100%; }
  .Input_input__3rPb3:hover {
    border-color: #dadada; }
  .Input_input__3rPb3:focus {
    border-color: #3279d6; }
  .Input_input__3rPb3::-webkit-input-placeholder {
    color: #dadada; }
  .Input_input__3rPb3::-moz-placeholder {
    color: #dadada; }
  .Input_input__3rPb3:-ms-input-placeholder {
    color: #dadada; }
  .Input_input__3rPb3::-ms-input-placeholder {
    color: #dadada; }
  .Input_input__3rPb3::placeholder {
    color: #dadada; }
  .Input_input__3rPb3[type='number'] {
    padding-right: 6px; }

.HoursPerWeek_label__bAIR2 {
  align-items: center;
  display: flex; }

.HoursPerWeek_input__3qe_W {
  margin-right: 14px;
  width: 64px; }
  .HoursPerWeek_input__3qe_W input[type='number'] {
    text-align: right; }

.Select_select__JK0vz {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMjU0Ljc1LDExIEMyNTUuMTY0MjE0LDExIDI1NS41LDExLjMzNTc4NjQgMjU1LjUsMTEuNzUgTDI1NS41LDE4LjQ5OSBMMjYyLjI1LDE4LjUgQzI2Mi42NjQyMTQsMTguNSAyNjMsMTguODM1Nzg2NCAyNjMsMTkuMjUgQzI2MywxOS42NjQyMTM2IDI2Mi42NjQyMTQsMjAgMjYyLjI1LDIwIEwyNTQuNzY0MzIyLDE5Ljk5OTg2NiBDMjU0Ljc1OTU1OSwxOS45OTk5NTUyIDI1NC43NTQ3ODUsMjAgMjU0Ljc1LDIwIEMyNTQuMzM1Nzg2LDIwIDI1NCwxOS42NjQyMTM2IDI1NCwxOS4yNSBMMjU0LDExLjc1IEMyNTQsMTEuMzM1Nzg2NCAyNTQuMzM1Nzg2LDExIDI1NC43NSwxMSBaIiB0cmFuc2Zvcm09InJvdGF0ZSgtNDUgMTE1LjYwMDUgMzEyLjY5MSkiIGZpbGw9IiMxMDEwMTAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4=");
  background-position: right 14px center;
  background-repeat: no-repeat;
  border-radius: 6px;
  border: 1px solid #cccccc;
  color: #101010;
  font-family: "Open Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  height: 40px;
  outline: none;
  padding: 0 36px 0 14px;
  width: 100%; }

.SignUp2_fillLater__1j6V_ {
  margin-bottom: 14px; }

.SignUp2_footer__2zVgZ {
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 0 24px;
  width: 100%; }

 {
  /* HTML5 display-role reset for older browsers */ }
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline; }
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block; }
  body {
    line-height: 1;
    overscroll-behavior-y: none; }
  ol, ul {
    list-style: none; }
  blockquote, q {
    quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }
  table {
    border-collapse: collapse;
    border-spacing: 0; }
  html, body, #root {
    height: 100%;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
  *, *::before, *::after {
    box-sizing: border-box; }

html, body {
  font-size: 14px; }

body, input, textarea {
  font-family: "Open Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #101010; }

h1 {
  color: #101010;
  font-size: 2.285714286rem;
  font-weight: 700; }

h2 {
  font-size: 1.285714286rem;
  font-weight: 700; }

h3 {
  font-size: 1.285714286rem;
  line-height: 1.65;
  font-weight: 500; }

p {
  font-size: 1rem;
  line-height: 1.571428571; }

a {
  color: #3279d6;
  outline: none;
  text-decoration: none; }

b {
  font-weight: 500; }

small {
  color: #6a6a6a;
  font-size: 1rem; }

