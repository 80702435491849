@import '../../styles/variables.scss';

.wrap {
  margin-bottom: $space-s;
  width: 230px;
}

.icon {
  margin-right: $space-xs;

  & svg {
    display: block;
  }
}

.provider--Google button {
  background-color: #4285f4 !important;

  &:hover {
    background-color: lighten(#4285f4, 2%) !important;
  }

  &:active {
    background-color: darken(#4285f4, 4%) !important;
  }
}

.provider--Twitter button {
  background-color: #1da1f2 !important;

  &:hover {
    background-color: lighten(#1da1f2, 2%) !important;
  }

  &:active {
    background-color: darken(#1da1f2, 4%) !important;
  }
}

.provider--Github button {
  background-color: #333 !important;

  &:hover {
    background-color: lighten(#333, 2%) !important;
  }

  &:active {
    background-color: darken(#333, 4%) !important;
  }
}
