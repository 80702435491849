@import '../../styles/variables.scss';

.wrap {
  text-align: center;
  width: 18px;
  margin-left: $space-xs - 2;
}

.label {
  color: $n1;
  cursor: pointer;
  font-weight: 700;
  padding-top: $space-xxs;
  text-transform: uppercase;
  user-select: none;
}
