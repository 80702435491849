@import '../../styles/variables.scss';

.frame {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.fullPage {
  height: 100vh;
  width: 100vw;
}
