$distance: 8px;
@import '../../styles/variables.scss';

.wrap {
  overflow: hidden;
}

.circle {
  fill: $n2;
}

.swig {
  animation: wiggle 1.2s linear infinite;
}

.theme--default {
  .circle {
    fill: $n2;
  }

  .swig {
    fill: $white;
  }
}

.theme--blue {
  .circle {
    fill: rgba($white, 0.75);
  }

  .swig {
    fill: $blue-500;
  }
}

/* prettier-ignore */
@keyframes wiggle {
  0% { transform: translateX(0px) }
  25% { transform: translateX((-$distance)) }
  50% { transform: translateX(0) }
  75% { transform: translateX($distance) }
  100% { transform: translateX(0) }
}
