@import '../../styles/variables.scss';

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 92vh;
  justify-content: flex-start;
  max-height: 1000px;
  padding: $space-m;
  position: relative;
}

.publicNavigation {
  width: 100%;
}

.welcome {
  margin: $space-xl 0;
  max-width: 900px;
  text-align: center;
}

.screenshot {
  height: calc(85vh - #{$space-xl + $space-l});
  left: 50%;
  max-width: 900px;
  overflow: hidden;
  position: absolute;
  top: $space-xl + $space-l;
  transform: translateX(-50%);
  width: 100%;

  & img {
    width: 100%;
  }
}

.text {
  background-color: $white;
  bottom: 0;
  left: 50%;
  max-width: 900px;
  padding: $space-m 0 $space-xl 0;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;

  &::before {
    background-image: linear-gradient(
      to bottom,
      rgba($white, 0%) 0%,
      rgba($white, 100%) 90%
    );
    content: '';
    height: 150px;
    left: 0;
    position: absolute;
    top: -150px;
    width: 100%;
  }
}

.title {
  margin-bottom: $space-m;
}

.subtitle {
  max-width: 575px;
  margin: 0 auto $space-l auto;
}

.signUp {
  margin-bottom: $space-s;
}

.features {
  background-color: $n4;
  height: 800px;
}
