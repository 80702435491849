@import '../../styles/variables.scss';

.button {
  appearance: none;
  background-color: $blue-500;
  border-radius: $roundness-m;
  border: 0;
  color: $white;
  cursor: pointer;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 700;
  height: 36px;
  outline: none;
  padding: 0 $space-m;
  position: relative;
  user-select: none;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
    user-select: none;
  }
}

.text {
  align-items: center;
  display: flex;
  justify-content: center;
}

.loading {
  pointer-events: none;

  .text {
    visibility: hidden;
  }
}

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: $space-xxs;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & svg {
    display: block;
  }
}

/** Themes */
.default {
  background-color: $blue-500;

  &:hover {
    background-color: lighten($blue-500, 4%);
  }

  &:active {
    background-color: darken($blue-500, 4%);
  }
}

.secondary {
  background-color: transparent;
  border: 2px solid $n0;
  color: $n0;

  &:hover {
    background-color: $n4;
  }

  &:active {
    background-color: $n3;
  }
}

.fill {
  width: 100%;
}
