@import '../../styles/variables.scss';

.select {
  appearance: none;
  background-color: $white;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMjU0Ljc1LDExIEMyNTUuMTY0MjE0LDExIDI1NS41LDExLjMzNTc4NjQgMjU1LjUsMTEuNzUgTDI1NS41LDE4LjQ5OSBMMjYyLjI1LDE4LjUgQzI2Mi42NjQyMTQsMTguNSAyNjMsMTguODM1Nzg2NCAyNjMsMTkuMjUgQzI2MywxOS42NjQyMTM2IDI2Mi42NjQyMTQsMjAgMjYyLjI1LDIwIEwyNTQuNzY0MzIyLDE5Ljk5OTg2NiBDMjU0Ljc1OTU1OSwxOS45OTk5NTUyIDI1NC43NTQ3ODUsMjAgMjU0Ljc1LDIwIEMyNTQuMzM1Nzg2LDIwIDI1NCwxOS42NjQyMTM2IDI1NCwxOS4yNSBMMjU0LDExLjc1IEMyNTQsMTEuMzM1Nzg2NCAyNTQuMzM1Nzg2LDExIDI1NC43NSwxMSBaIiB0cmFuc2Zvcm09InJvdGF0ZSgtNDUgMTE1LjYwMDUgMzEyLjY5MSkiIGZpbGw9IiMxMDEwMTAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4=');
  background-position: right $space-xs center;
  background-repeat: no-repeat;
  border-radius: $roundness-m;
  border: 1px solid $n2;
  color: $n0;
  font-family: $font-stack;
  font-size: 1rem;
  height: 40px;
  outline: none;
  padding: 0 $space-l 0 $space-xs;
  width: 100%;
}
