$black: #000000;
$white: #ffffff;

$n0: #101010;
$n1: #6a6a6a;
$n2: #cccccc;
$n3: #dadada;
$n4: #f4f4f4;

$blue-500: #3279d6;
$green-500: #297c2c;
$red-500: #d91c1c;

$brand: $blue-500;
