@import '../../styles/variables.scss';

.label {
  align-items: center;
  display: flex;
}

.input {
  margin-right: $space-xs;
  width: 64px;

  & input[type='number'] {
    text-align: right;
  }
}
